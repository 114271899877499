import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/data";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

import global_en from "./components/constants/lang/en/global.json";
import global_kor from "./components/constants/lang/kor/global.json";
import global_uzb from "./components/constants/lang/uzb/global.json";
import global_vet from "./components/constants/lang/vet/global.json";
import global_thai from "./components/constants/lang/thai/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    kor: {
      global: global_kor,
    },
    uzb: {
      global: global_uzb,
    },
    vet: {
      global: global_vet,
    },
    thai: {
      global: global_thai,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <ToastContainer />
      <App />
    </I18nextProvider>
  </BrowserRouter>
);
