import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import Select, { SelectOption } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import en_flag from "../assets/lang/en.png";
import kor_flag from "../assets/lang/kor.png";
import uzb_flag from "../assets/lang/uzb.png";
import vet_flag from "../assets/lang/vet.png";
import thai_flag from "../assets/lang/thai.png";

// Define the type for the props
type TranslateComponentProps = {
  handleChangeLanguage: (lang: string, label: string) => void;
};

const options = [
  { value: "en", label: "English", src: `${en_flag}` },
  { value: "kor", label: "Korean", src: `${kor_flag}` },
  { value: "uzb", label: "Uzbek", src: `${uzb_flag}` },
  { value: "vet", label: "Vietnamese", src: `${vet_flag}` },
  { value: "thai", label: "Thai", src: `${thai_flag}` },
];

function renderValue(option: SelectOption<string> | null) {
  if (!option) {
    return null;
  }

  return (
    <React.Fragment>
      <ListItemDecorator>
        <Avatar
          size="sm"
          src={options.find((o) => o.value === option.value)?.src}
        />
      </ListItemDecorator>
      {option.label}
    </React.Fragment>
  );
}

export default function TranslateComponent({
  handleChangeLanguage,
}: TranslateComponentProps) {
  const handleOptionClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const lang = (event.currentTarget as HTMLElement).getAttribute(
      "data-value"
    );
    const label = (event.currentTarget as HTMLElement).getAttribute(
      "data-label"
    );
    if (lang && label) {
      handleChangeLanguage(lang, label);
    }
  };

  return (
    <Select
      defaultValue="en"
      slotProps={{
        listbox: {
          sx: {
            "--ListItemDecorator-size": "44px",
          },
        },
      }}
      sx={{
        "--ListItemDecorator-size": "44px",
        minWidth: 240,
      }}
      renderValue={renderValue}
    >
      {options.map((option, index) => (
        <React.Fragment key={option.value}>
          {index !== 0 ? (
            <ListDivider role="none" inset="startContent" />
          ) : null}
          <Option
            value={option.value}
            label={option.label}
            data-value={option.value}
            data-label={option.label}
            onClick={handleOptionClick}
          >
            <ListItemDecorator>
              <Avatar size="sm" src={option.src} />
            </ListItemDecorator>
            {option.label}
          </Option>
        </React.Fragment>
      ))}
    </Select>
  );
}
